import React from 'react';
import { Container, Header, Box, Link } from "@awsui/components-react";

const ContactInfo = () => {
  return (
    <Container 
      header={
        <Header variant="h3">
          Contact Information
        </Header>
      }
      className="mt-6"
    >
      <Box variant="p" className="mb-4">
        <strong>MSEA Pacific Science Data Stewardship Unit</strong>
        <br />
        <Link external href="mailto:DFO.PACScienceMSEAData-DonneesEASMSciencePAC.MPO@dfo-mpo.gc.ca">
          DFO.PACScienceMSEAData-DonneesEASMSciencePAC.MPO@dfo-mpo.gc.ca
        </Link>
      </Box>

      <Box variant="p" className="mb-4">
        <strong>Team Members:</strong>
        <br />
        Cole Fields
        <br />
        <Link external href="mailto:Cole.Fields@dfo-mpo.gc.ca">
          Cole.Fields@dfo-mpo.gc.ca
        </Link>
      </Box>

      <Box variant="p">
        Kathy Charles (she, her / elle, la)
        <br />
        <Link external href="mailto:Katherine.Charles@dfo-mpo.gc.ca">
          Katherine.Charles@dfo-mpo.gc.ca
        </Link>
      </Box>
    </Container>
  );
};

export default ContactInfo;